<template>
  <div>
    <PageHeader title="Contact Us"></PageHeader>
    <v-card color="cardBackground" justify="center" class="mt-10 py-6">
      <div class="mb-4" v-for="({ href, icon, title, subTitle }, i) of method" :key="i">
        <v-btn text x-large block :href="href" class="mainTextColor--text">
          <v-icon large class="mr-3">{{ icon }}</v-icon>
          <div style="display: contents">{{ title }}</div>
          <div v-if="subTitle">{{ subTitle }}</div>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'contactUs',
  directives: {},
  components: { PageHeader: () => import('@/components/app/PageHeader') },
  created() {},
  data: () => ({
    method: [
      {
        href: 'mailto:register@thetitlegirl.com',
        icon: 'mdi-email',
        title: 'register@thetitlegirl.com',
      },
      {
        href: 'tel:+12149976005',
        icon: 'mdi-phone',
        title: '(214) 997-6005',
      },
      {
        href: 'https://goo.gl/maps/f9PnMo3xPJXWMrpz5',
        icon: 'mdi-map-marker',
        title: '16775 Addison Road, Suite 400, Dallas, TX',
        // subTitle: 'Addison, TX 75001'
      },
    ],
  }),
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
